<template>
  <section class="full-height center-center">
    <div class="container">
      <div class="full-height top-center flex-column th-my-40">
        <div class="th-my-24">
          <header-logo :light="false" :to="homePageRoute" />
        </div>
        <v-card width="450">
          <div class="d-flex flex-column th-py-48 th-px-24">
            <div class="text-center th-mb-36">
              <h5 v-html="$translation.t('Reset your password')"></h5>
              <p
                class="text-muted"
                v-html="
                  this.$translation.t(
                    'You have requested to reset a new password'
                  )
                "
              ></p>
            </div>

            <v-form ref="passwordReset">
              <v-text-field
                :label="$translation.t('Email')"
                :rules="[rules.email]"
                outlined
                id="email"
                name="email"
                v-model="email"
                class="mb-3"
                hide-details="auto"
              ></v-text-field>
              <v-text-field
                :label="$translation.t('Password')"
                :rules="[rules.required, rules.password8]"
                outlined
                id="password"
                name="password"
                type="password"
                v-model="password"
                class="mb-3"
                hide-details="auto"
              ></v-text-field>
              <v-text-field
                :label="$translation.t('Password Confirmation')"
                :rules="[rules.required, checkPasswordSame]"
                outlined
                id="passwordConfirmation"
                name="passwordConfirmation"
                type="password"
                v-model="passwordConfirmation"
                class="mb-3"
                hide-details="auto"
              ></v-text-field>
            </v-form>
            <div class="text-danger th-my-16" v-if="error">
              {{ error }}
              <ul>
                <li v-for="(e, ek) in errorInputs" :key="ek">
                  {{ e.join(",") }}
                </li>
              </ul>
            </div>
            <v-btn
              id="reset_password_submit"
              block
              color="primary"
              large
              :loading="resetting"
              @click.native="passwordReset"
            >
              {{ $translation.t("Reset your password") }}
            </v-btn>
          </div>
        </v-card>
      </div>
    </div>
  </section>
</template>

<script>
import HeaderLogo from "@/components/Layout/Header/HeaderLogo";
import rules from "@/utils/inputRules";

export default {
  name: "PasswordReset",
  components: { HeaderLogo },
  data: () => ({
    rules,
    error: "",
    errorInputs: [],
    email: "",
    password: "",
    passwordConfirmation: "",
    resetting: false,
  }),
  created() {
    this.email = this.$route?.query?.email || "";
  },
  computed: {
    homePageRoute() {
      return window.location.origin + `/${this.$route.params.locale || ""}`;
    },
  },
  methods: {
    async passwordReset() {
      if (!this.$refs.passwordReset.validate()) return;

      this.resetting = true;

      try {
        await this.$axios.put("/api/v1/auth/password-reset", {
          email: this.email,
          password: this.password,
          password_confirmation: this.passwordConfirmation,
          token: this.$route.params.token,
          business: true,
        });

        this.$router.push({
          name: "login",
          params: {
            email: this.email,
            password: this.password,
          },
        });
      } catch (e) {
        this.error = e.response.data.message;
        this.errorInputs = e.response.data.inputs || [];
        this.$log.notify(e);
      }

      this.resetting = false;
    },
    checkPasswordSame(value) {
      return value === this.password
        ? true
        : this.$translation.t("Passwords must match");
    },
  },
};
</script>

<style scoped></style>
